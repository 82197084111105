<template>
  <div>
    <!-- Table Container Card -->
    <b-card>
      <div class="m-2">
        <b-row class="filter-row" style="padding: 0 10px">
          <b-col cols="12" md="6" class="px-0 order-md-1 pb-1">
            <h4>ساخت منو</h4>
          </b-col>
        </b-row>
        <!-- Table Top -->
        <b-row>
          <b-col
            class="p-0 mb-1"
            cols="12"
            md="6"
            style="padding: 0 10px !important"
          >
            <h6>نام</h6>
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="formData.name"
                class="d-inline-block"
                placeholder="نام منو"
              />
            </div>
          </b-col>
          <b-col
            class="p-0 mb-1"
            cols="12"
            md="6"
            style="padding: 0 10px !important"
          >
            <h6>اسلاگ</h6>
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="formData.slug"
                class="d-inline-block"
                placeholder="اسلاگ"
              />
            </div>
          </b-col>
        </b-row>
        <b-row
          class="d-flex justify-content-end pt-md-1"
          style="padding: 0 10px"
        >
          <!-- disable it with form validation -->
          <b-button
            class="d-flex justify-content-center align-items-center"
            variant="primary"
            type="submit"
            :disabled="isLoading"
            @click="createMenu"
          >
            <span class="text-nowrap">ساخت منوی جدید</span>
            <b-spinner
              v-show="isLoading"
              class="position-absolute"
              small
              type="grow"
            />
          </b-button>
        </b-row>
      </div>

      <!-- Table -->
      <LoadingComponent v-if="loading" />
      <b-col style="padding: 0 15px !important" v-else>
        <div v-if="menus.length > 0">
          <div class="pt-1 pb-1 overflow" v-if="menus.length > 0">
            <vue-good-table
              :sort-options="{ enabled: false }"
              :columns="columns"
              :rows="menus"
              :rtl="false"
              class="w-100"
            >
              <div slot="emptystate" class="w-100 text-center">
                موردی یافت نشد
              </div>
              <template slot="table-row" slot-scope="props" class="mx-auto">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'id'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.id }}</span>
                </span>
                <span v-if="props.column.field === 'name'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.name }}</span>
                </span>
                <span v-if="props.column.field === 'slug'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.slug }}</span>
                </span>
                <span
                  v-if="props.column.field === 'thumbnail'"
                  class="text-nowrap"
                >
                  <b-avatar
                    :src="props.row.thumbnail"
                    size="3rem"
                    class="text-nowrap"
                  ></b-avatar>
                </span>
                <span
                  v-if="props.column.field === 'status'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">{{ props.row.status }}</span>
                </span>

                <!-- Column: Action -->
                <span
                  v-else-if="props.column.field === 'action'"
                  style="width: 100px !important"
                >
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      :right="true"
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item @click="moveToEditMenu(props.row.id)">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>ویرایش</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="deletePrompt(props.row.id)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>حذف</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
            </vue-good-table>
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalMenus"
            :per-page="perPage"
            first-number
            last-number
            align="left"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0 p-0"
            dir="ltr"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
        <div v-else>
          <h3 class="text-center">موردی یافت نشد</h3>
        </div>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BSpinner,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BPagination,
  BTable,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import axios from "axios";
import { VueGoodTable } from "vue-good-table";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LoadingComponent from "../../components/LoadingComponent.vue";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCard,
    BSpinner,
    BFormInput,
    BRow,
    BTable,
    BCol,
    BButton,
    BPagination,
    BDropdownItem,
    BDropdown,
    ToastificationContent,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      menus: [],
      menusLength: 0,
      menusTray: [],
      totalMenus: 0,
      perPage: 15,
      currentPage: 1,
      columns: [
        {
          label: "شناسه",
          field: "id",
        },
        {
          label: "عنوان",
          field: "name",
        },
        {
          label: "اسلاگ",
          field: "slug",
        },
        {
          label: "عملیات",
          field: "action",
        },
      ],
      formData: {
        name: null,
        slug: null,
      },
    };
  },
  methods: {
    getMenus() {
      this.loading = true;
      axios
        .get("/api/v1/admin/menu", { params: { page: this.currentPage } })
        .then((response) => {
          this.menusLength = response.data.data.length;
          this.totalMenus = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          if (this.menusLength > 0) {
            this.menusTray = response.data.data;
            this.menus = [];
            this.menusTray.forEach((item) => {
              this.menus.push({
                id: item.id,
                name: item.name,
                slug: item.slug,
              });
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    deletePrompt(id) {
      this.$bvModal
        .msgBoxConfirm('آیا از حذف مطمئن هستید؟', {
          title: '',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'بله',
          cancelTitle: 'خیر',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value==true) {
            this.deleteMenus(id)
          }
        })
    },
    deleteMenus(id) {
      axios
        .post(`/api/v1/admin/menu/${id}/delete`)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "منو حذف شد",
              icon: "CheckIcon",
              text: "منو با موفیقت حذف شد.",
              variant: "info",
            },
          });
          this.getMenus();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    moveToEditMenu(id) {
      router.push(`/menus/update/${id}`);
    },
    createMenu() {
      this.isLoading = true;
      axios
        .post("/api/v1/admin/menu/create", this.formData)
        .then((response) => {
          this.getMenus();
          this.formData.name = "";
          this.formData.slug = "";

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "منو ساخته شد",
              icon: "CheckIcon",
              text: "منو با موفیقت ساخته شد",
              variant: "success",
            },
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
  },
  mounted() {
    this.getMenus();
    this.$watch("currentPage", (newCurrentPage) => {
      this.getMenus();
    });
  },
  unmounted() {
    this.$watch("currentPage");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.per-page-selector {
  width: 90px;
}
</style>